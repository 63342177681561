import styled from 'styled-components';
import { variant, alignItems, boxShadow } from 'styled-system';
import { buttonStyle, colorStyle, sizeStyle } from '../customVariant';
import { base } from '../base';
import { rgba } from "polished"
import { themeApply, themeRequire } from "../theme-utils"

const ButtonStyle = styled.button`
  /* button default style */
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${themeRequire('colors.white')};
  background-color: ${themeRequire('colors.transparent')};
  min-height: ${themeRequire('heights.3')}px;
  min-width: ${themeRequire('widths.3')}px;
  border-radius: ${themeRequire('radius.0')}px;
  font-family: inherit;
  font-size: ${themeRequire('fontSizes.4')}px;
  font-weight: ${themeRequire('fontWeights.4')};
  text-decoration: none;
  text-transform: capitalize;
  padding-top: ${themeRequire('space.2')}px;
  padding-bottom: ${themeRequire('space.2')}px;
  padding-left: ${themeRequire('space.4')}px;
  padding-right: ${themeRequire('space.4')}px;
  border: 0;
  transition: all 0.3s ease;
  span.btn-text {
    padding-left: ${themeRequire('space.1')}px;
    padding-right: ${themeRequire('space.1')}px;
  }
  span.btn-icon {
    display: flex;
    > div {
      display: flex !important;
    }
  }

  &:focus {
    outline: none;
  }

  /* Material style goes here */
  &.is-material {
    box-shadow:
      0px 1px 5px 0px ${themeApply('colors.black', color => rgba(color, 0.2))},
      0px 2px 2px 0px ${themeApply('colors.black', color => rgba(color, 0.14))},
      0px 3px 1px -2px ${themeApply('colors.black', color => rgba(color, 0.12))};
  }

  /* When button on loading stage */
  &.is-loading {
    .btn-text {
      padding-left: ${themeRequire('space.2')}px;
      padding-right: ${themeRequire('space.2')}px;
    }
  }

  /* Style system support */
  ${alignItems}
  ${boxShadow}
  ${buttonStyle}
  ${colorStyle}
  ${sizeStyle}
  ${base}
`;

// prop types can also be added from the style functions
ButtonStyle.propTypes = {
  ...alignItems.propTypes,
  ...boxShadow.propTypes,
  ...variant.propTypes,
};

ButtonStyle.displayName = 'ButtonStyle';

export default ButtonStyle;
